import * as React from "react";

import Layout from "../layouts/Layout";

import logo from "../images/logo-dark.svg";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <Layout>
      <Helmet>
        <title>Whistleblower - Polityka Prywatności</title>
        <meta
          name="description"
          content="Polityka prywatności systemu Whistleblower. Jest to dwukierunkowy kanał komunikacji, spełniający wymogi dyrektywy."
        />
        <link
          rel="alternate"
          href="https://whistleblower.pl/privacy/"
          hrefLang="pl-PL"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 justify-between">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <img className="h-10 w-auto sm:h-14" src={logo} alt="Logo" />
              </a>
            </div>
          </div>
        </nav>
      </div>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>

        <div className="relative px-4 sm:px-6 lg:px-8 flex justify-center">
          <div class="relative w-full max-w-7xl md:px-4 lg:px-6">
            <div class="overflow-hidden my-6 align-top bg-gray-100 rounded-md shadow sm:mx-auto sm:w-full md:mx-4">
              <div class="">
                <h3 class="my-4 block text-3xl text-center font-extrabold tracking-tight text-blue-500">
                REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ
                </h3>
                <h3 class="my-4 block text-xl text-center font-bold tracking-tight">
                sporządzony na podstawie ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną
                </h3>
              </div>

              <div class="px-2 pb-8 mx-2 text-justify sm:px-8 sm:mx-4">
                <p class="mt-2">
                  Regulamin świadczenia usług wraz z informacją o prywatności określa zasady przetwarzania i ochrony danych osobowych przekazywanych/udostępnianych przez Użytkowników w związku z korzystaniem przez nich z usług świadczonych drogą elektroniczną przez Serwis o adresie elektronicznym: <span class="text-sm text-blue-500 underline"><a href="acc.whistleblower.pl">https://whistleblower.pl</a></span> (dalej zwany: „Serwisem”). Administratorem danych osobowych zawartych w Serwisie jest Fib.Code Sp. z o.o., z siedzibą w Olkuszu, 32-300 Olkusz, ul. Rynek 29, NIP: 637-220-84-21, wpisana do Krajowego Rejestru Sądowego - Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie pod numerem: 0000804872, prowadząca Serwis za pośrednictwem sieci Internet.
                </p>

                <p class="mt-2 font-bold">
                  Definicje
                </p>

                <p class="mt-2">
                Wyrażenia pisane w niniejszym Regulaminie wielką literą będą miały następujące znaczenie, chyba że z jego treści wynika co innego:
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                    Regulamin
                  </span>
                  - niniejszy Regulamin.
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                    Administrator Serwisu
                  </span>
                  - Fib.Code Sp. z o.o. z siedzibą w Olkuszu, 32-300 Olkusz, ul. Rynek 29, NIP: 637-220-84-21.
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                    Serwis
                  </span>
                  - prowadzony przez Administratora Serwis internetowy dostępny pod adresem elektronicznym: https://www.whistleblower.pl/.
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                  Użytkownik
                  </span>
                  - osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej mająca pełną zdolność do czynności prawnych, korzystająca z Serwisu na zasadach określonych w Regulaminie.
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                  Zgłoszenie
                  </span>
                  - działanie polegające na kliknięciu przycisku „Wyślij”, które umożliwia kontakt z Administratorem w ramach konkretnej usługi dostępnej dla Użytkowników w Serwisie.
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                  Strona internetowa
                  </span>
                  - każda indywidualna zakładka lub podstrona dostępna w ramach Serwisu..
                </p>

                <p class="mt-2">
                  <span class="font-bold">
                  Usługa
                  </span>
                  - funkcjonalność dostępna w ramach Serwisu, która umożliwia Użytkownikowi kontakt z Administratorem.
                </p>

                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 1 Zasady korzystania z Serwisu</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    Każdy Użytkownik Serwisu zobowiązany jest do przestrzegania Regulaminu. Wszelkie działania podejmowane w ramach Serwisu powinny być zgodne z obowiązującymi przepisami prawa, dobrymi obyczajami i zasadami współżycia społecznego.
                  </li>
                  <li>
                    Administrator Serwisu udostępnia Użytkownikom dostęp do treści Serwisu: tj. świadczy usługi drogą elektroniczną w rozumieniu Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną. Usługodawcą w rozumieniu przedmiotowej Ustawy jest Administrator Serwisu.
                  </li>
                  <li>
                    Do korzystania z Serwisu wymagane jest: połączenie z siecią Internet, przeglądarka internetowa umożliwiająca wywoływanie i przeglądanie na ekranie komputera lub innego urządzenia elektronicznego stron www oraz obsługująca język programowania JavaScript, a ponadto obsługująca szyfrowane połączenia SSL.
                  </li>
                  <li>
                    Wszelkie prawa do Serwisu oraz jego elementów stanowią przedmiot praw Administratora, natomiast wszelkie materiały lub ich elementy, w tym: teksty, grafika, układ i kompozycja elementów (tzw. layout), znaki towarowe, fotografie, materiały audio-video, aplikacje oraz inne oprogramowanie i inne informacje i elementy chronione prawem dostępne na stronach internetowych Serwisu stanowią przedmiot praw Administratora. Każde ich niezgodne z prawem wykorzystanie jest zabronione i może rodzić odpowiedzialność cywilnoprawną lub karną. Oznacza to w szczególności zakaz kopiowania, modyfikowania, zwielokrotniania, rozpowszechniania w jakiejkolwiek postaci takich materiałów z wyjątkiem przypadków dozwolonych przez prawo. Informacje na temat możliwości uzyskania licencji na korzystanie z poszczególnych materiałów dostępnych w ramach Serwisu można uzyskać pisząc na adres elektroniczny poczty e-mail: <span class="text-sm text-blue-500 underline"><a href="mailto:office@fibcode.com">office@fibcode.com</a></span>.
                  </li>
                  <li>
                    Ilekroć w Regulaminie przewidziano składanie jakichkolwiek oświadczeń przy pomocy narzędzi udostępnianych przez Administratora w ramach Serwisu, wszelkie oświadczenia złożone w ten sposób uważa się za złożone z chwilą skorzystania przez Użytkownika lub innej osoby korzystającej z Serwisu z odpowiedniego narzędzia, bez względu na to, kiedy oświadczenie to doszło do jego adresata w taki sposób, że mógł się on z nim zapoznać. Użytkownik lub inna osoba korzystająca z Serwisu może skontaktować się z Administratorem w celu ustalenia, czy składane przez niego oświadczenie lub oświadczenie składane w jego imieniu przez innego Użytkownika zostało złożone.
                  </li>
                  <li>
                    Regulamin jest udostępniany osobom korzystającym z Serwisu nieodpłatnie za pośrednictwem Serwisu w formie, która umożliwia jego pobranie, utrwalenie i wydrukowanie.
                  </li>
                  <li>
                  Administrator przy przetwarzaniu danych Użytkowników stosuje się do wymogów przewidzianych w: 
                    <ol class="list-[lower-latin] ml-4">
                      <li>
                        Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE; 
                      </li>
                      <li>
                        Ustawie z dnia 10 maja 2018 r. o ochronie danych osobowych.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 2 Odpowiedzialność</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    Administrator nie ponosi odpowiedzialności za jakiekolwiek działania lub zaniechania Użytkowników związane z korzystaniem z Serwisu.
                  </li>
                  <li>
                    Administrator nie gwarantuje, że dane osobowe udostępnione przez Użytkowników odnośnie ich tożsamości są prawdziwe.
                  </li>
                  <li>
                    Administrator zapewnia poufność Użytkownika w przypadku udostępnienia danych pozwalających na identyfikację osoby fizycznej, w przypadku uzupełnienia formularza do kontaktu.
                  </li>
                  <li>
                    Serwis i wszystkie świadczone w jego ramach usługi są udostępnione w stanie takim, w jakim się znajdują. Korzystanie z Serwisu jest równoznaczne ze złożeniem przez Użytkownika oświadczenia, że zapoznał się ze sposobem funkcjonowania Serwisu i akceptuje go w tej formie, w jakiej się znajduje. Nie wyłącza to uprawnienia Administratora do wprowadzania nowych rozwiązań technicznych, w tym takich, których celem jest usprawnienie funkcjonowania Serwisu i świadczonych w jego ramach usług.
                  </li>
                  <li>
                    Administrator nie ponosi odpowiedzialności za zakłócenia w funkcjonowaniu Serwisu lub utratę danych Użytkownika w wyniku: działania siły wyższej lub świadomej winy Użytkownika.
                  </li>
                  <li>
                    W najpełniejszym zakresie, w jakim jest to dopuszczalne przez bezwzględnie obowiązujące przepisy prawa, odpowiedzialność Administratora za szkodę rzeczywistą oraz utracone przez Użytkownika korzyści zostaje wyłączona, chyba że szkoda została wyrządzona z winy umyślnej lub rażącego niedbalstwa Administratora Serwisu.
                  </li>
                  <li>
                    W pozostałym zakresie odpowiedzialność Administratora za szkodę rzeczywistą wyrządzoną Użytkownikowi oraz utracone przez Użytkownika korzyści zostaje ograniczona w następujący sposób: 
                    <ol class="list-[lower-latin] ml-4">
                      <li>
                        Administrator odpowiada jedynie w razie stwierdzenia winy umyślnej lub rażącego niedbalstwa;                       </li>
                      <li>
                        całkowita odpowiedzialność Administratora względem Użytkownika jest ograniczona do kwoty 1000,00 zł.                      </li>
                    </ol>
                  </li>
                  <li>
                    Użytkownik ponosi wobec Administratora pełną odpowiedzialność za szkody wynikłe lub związane z naruszeniem przez niego prawa, Regulaminu, dobrych obyczajów, zasad współżycia społecznego lub praw osób trzecich, w tym w szczególności za szkody związane z nieprawdziwością, niedokładnością lub niepełnością jakichkolwiek z oświadczeń Użytkownika.
                  </li>
                </ol>

                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 3 Kontakt i reklamacje</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    Administrator udostępnia Użytkownikowi adres elektroniczny poczty e-mail: <span class="text-sm text-blue-500 underline"><a href="mailto:office@fibcode.com">office@fibcode.com</a></span>, przeznaczony do kontaktów z nim, na który Użytkownik może kierować wszelkie zapytania, sugestie, reklamacje, uwagi i informacje.
                  </li>
                  <li>
                    Reklamacje dotyczące funkcjonowania Serwisu winny być zgłaszane na adres elektroniczny poczty e-mail: <span class="text-sm text-blue-500 underline"><a href="mailto:office@fibcode.com">office@fibcode.com</a></span> lub pisemnie na adres siedziby Administratora Serwisu.
                  </li>
                  <li>
                    Użytkownik może złożyć reklamację, jeżeli usługi są realizowane przez Administratora Serwisu niezgodnie z postanowieniami Regulaminu.
                  </li>
                  <li>
                    Reklamacja powinna zawierać co najmniej adres elektroniczny poczty e-mail i nazwę Użytkownika.
                  </li>
                  <li>
                    Administrator Serwisu może zwrócić się do składającego reklamację, jeżeli wskazane w niej dane lub informacje wymagają uzupełnienia.
                  </li>
                  <li>
                    Administrator Serwisu rozpoznaje reklamacje w terminie 14 dni od daty jej otrzymania w prawidłowej postaci, z zastrzeżeniem, że Administrator Serwisu może odmówić rozpatrzenia reklamacji złożonych po upływie 90 dni od ujawnienia się przyczyn reklamacji.
                  </li>
                  <li>
                    Odpowiedź na reklamację zostanie wysłana na adres poczty elektronicznej e-mail składającego reklamację.
                  </li>
                </ol>

                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 4 Zmiany Regulaminu</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    W razie wprowadzenia zmian w Regulaminie, Administrator poinformuje o tym fakcie Użytkowników za pomocą publikacji zmian na stronie internetowej Serwisu.
                  </li>
                  <li>
                    Zmiana danych Administratora lub danych kontaktowych nie wymaga stosowania procedury opisanej w niniejszym paragrafie - Administrator Serwisu jest jednak zobowiązany powiadomić Użytkowników o takich zmianach.
                  </li>
                </ol>

                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 5 Przetwarzanie danych osobowych</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    Polityka przetwarzania metadanych w postaci plików cookie w ramach Serwisu znajduje się pod adresem elektronicznym: <span class="text-sm text-blue-500 underline"><a href="https://fibcode.com/o-nas/polityka-cookies/">https://fibcode.com/o-nas/polityka-cookies/</a></span> 
                  </li>
                  <li>
                    Kategorie przetwarzanych danych osobowych w ramach Serwisu obejmują: IP (w ramach odwiedzających Serwis) oraz adres elektroniczny poczty e-mail, imię oraz nazwisko, telefon - w przypadku zainteresowania ofertą i złożenia zapytania poprzez usługę udostępnioną w ramach Serwisu za dobrowolną i świadomą zgodą Użytkownika, wyrażoną poprzez wysłanie Zgłoszenia.
                  </li>
                  <li>
                    Dane osobowe nie będą podlegały procesom profilowania.
                  </li>
                  <li>
                    Podstawą przetwarzania danych osobowych jest dobrowolnie wyrażona zgoda Użytkownika poprzez przesłanie zgłoszenia oraz przeglądanie treści udostępnionych w Serwisie przez Administratora, w tym prawnie uzasadniony interes w przypadku korzystania z narzędzi do anonimowego prowadzenia statystyk w ramach stron internetowych udostępnionego Serwisu.
                  </li>
                  <li>
                    Okres przechowywania danych osobowych dla plików cookie został określony w Polityce, o której mowa w pkt 1. W przypadku danych osobowych udostępnionych w treści formularzy, ich okres przechowywania będzie dotyczył realizacji działań związanych z czynnościami przed zawarciem umowy, takich jak wysłanie oferty zwrotnej, udzielenie odpowiedzi, czy czynności związane z reklamacją.
                  </li>
                  <li>
                    Dane osobowe Użytkownika mogą być przekazywane innym podmiotom, które na podstawie stosownych umów podpisanych z Administratorem przetwarzają dane osobowe na polecenie Administratora lub w charakterze osobnego Administratora danych w celu realizacji usługi.
                  </li>
                  <li>
                    Użytkownikom przysługują prawa do: 
                    <ol class="list-[lower-latin] ml-4">
                      <li>
                        uzyskania informacji na temat przetwarzania danych, w tym o kategoriach przetwarzanych danych i ewentualnych odbiorcach danych; 
                      </li>
                      <li>
                        żądania skorygowania nieprawidłowych danych lub uzupełnienia niekompletnych danych; 
                      </li>
                      <li>
                        żądania usunięcia danych - poprzez zgłoszenie sprzeciwu wobec ich przetwarzania; 
                      </li>
                      <li>
                        żądania ograniczenia przetwarzania danych - jeżeli spełnione zostaną wymogi prawne uzasadniające takie ograniczenie; 
                      </li>
                      <li>
                        przenoszenia danych – poprzez otrzymanie danych w formacie umożliwiającym ich przekazanie wybranemu przez Użytkownika podmiotowi trzeciemu; 
                      </li>
                      <li>
                        cofnięcia zgody, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem; 
                      </li>
                      <li>
                        złożenia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00 - 193 Warszawa – w przypadku stwierdzenia, że dane są przetwarzane sprzecznie z prawem.
                      </li>
                    </ol>
                  </li>
                  <li>
                  W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - RODO, Ustawa o ochronie danych osobowych, Ustawa o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju innymi aktami wykonawczymi oraz prawa wspólnotowego. Dane są chronione środkami technicznymi i organizacyjnymi zgodnie z obowiązującymi przepisami. Administrator nie pobiera danych od podmiotów trzecich lub ze źródeł powszechnie dostępnych i przetwarza wyłącznie dane przekazane przez Użytkowników. Dane nie będą przekazywane do podmiotów trzecich ani do państw trzecich. Administrator Serwisu posiada certyfikat normy ISO/IEC 27001:2022 w zakresie świadczonych usług.
                  </li>
                </ol>


                <h4 class="my-4 w-full text-xl text-center font-bold ">§ 7 Postanowienia końcowe</h4>
                <ol class="pl-4 list-decimal my-2">
                  <li>
                    Regulamin wchodzi w życie z dniem jego opublikowania w Serwisie.
                  </li>
                  <li>
                    Ilekroć w Regulaminie przewidziano możliwość lub obowiązek skontaktowania się Użytkownika z Administratorem Serwisu, kontakt taki powinien nastąpić przy użyciu poczty elektronicznej (chyba że co innego wynika z Regulaminu) na adres elektroniczny poczty e-mail: <span class="text-sm text-blue-500 underline"><a href="mailto:office@fibcode.com">office@fibcode.com</a></span>
                  </li>
                  <li>
                    W sprawach nieuregulowanych Regulaminem mają zastosowanie odpowiednie przepisy prawa polskiego
                  </li>
                  <li>
                    Użytkownik ponosi odpowiedzialność za udostępniane w Serwisie lub za jego pośrednictwem materiały i wszelkie inne treści. Użytkownik zobowiązuje się: nie przesyłać treści naruszających przepisy prawa, umieszczać w ramach Usług nieprawdziwych, obraźliwych, wulgarnych, nawołujących do nienawiści, jak również treści, których publikacja mogłaby prowadzić do naruszenia praw lub dóbr osobistych osób trzecich. Użytkownik powstrzyma się również od udostępniania treści, wobec których nie przysługuje mu prawo do ich udostępniania (np. objętych prawami autorskimi osób trzecich) oraz co do których jest zobowiązany do zachowania poufności.
                  </li>
                  <li>
                    Z wyjątkiem informacji, o których mowa w Regulaminie, pełna komunikacja lub materiały wysyłane w ramach Usług przez odwiedzających: pocztą elektroniczną lub w jakikolwiek inny sposób, w tym: dane, pytania, komentarze, sugestie i tym podobne będą traktowane jako informacje niepoufne, nie stanowiące tajemnicy przedsiębiorstwa i niechronione, chyba że wskazano inaczej. Wszystkie materiały przesyłane lub umieszczane w ramach Usług stają się dostępne dla Usługodawcy z najszerszymi uprawnieniami do korzystania z nich, w tym na przykład do: powielania, przekazywania, publikacji i wysyłania. Usługodawca może również swobodnie wykorzystywać wszelkie pomysły, koncepcje, know-how lub techniki zawarte w komunikatach otrzymywanych przez Strony internetowe oraz Usługi w jakimkolwiek celu, w tym na przykład: rozwoju i wprowadzania do obrotu produktów opartych na takich informacjach.
                  </li>
                  <li>
                    Pomimo, że Administrator dokłada starań, aby okresowo monitorować materiały zawarte w ramach świadczonych Usług, nie może zapewnić, że działania wszystkich Użytkowników pozostaną zgodne z Regulaminem. W związku z tym Usługodawca nie ponosi odpowiedzialności w odniesieniu do udostępnionych przez Użytkowników treści i informacji zawartych w ramach Stron internetowych oraz Usług. Użytkownikom zabronione jest wysyłanie wiadomości obscenicznych, oszczerczych lub w jakikolwiek sposób sprzecznych z obowiązującymi przepisami prawa. Usługodawca będzie współpracować z władzami przy przestrzeganiu przepisów mających na celu identyfikację osób, które dopuszczają się nielegalnych działań polegających na wysyłaniu lub umieszczaniu zabronionych materiałów na Strony internetowe oraz w ramach Usług.
                  </li>
                  <li>
                    Usługodawca dokłada należytych starań, aby minimalizować ryzyka związane z korzystaniem z Usług, w tym ryzyka dotyczące spamu, szkodliwego oprogramowania lub form phishingu. W tym celu Usługodawca podejmuje środki zmierzające do zabezpieczenia serwerów, połączeń, Stron internetowych oraz Usług, niemniej jednak całkowite wyeliminowanie niebezpieczeństwa nie jest możliwe. Zachęcamy Użytkowników do zachowania czujności i stosowania dostępnych praktyk podczas korzystania z Usług, aby minimalizować ryzyko napotkania na zagrożenia. Praktyki, które można przedsięwziąć celem zwiększenia ochrony teleinformatycznej: 
                    <ol class="list-[lower-latin] ml-4">
                      <li>
                        nieotwieranie podejrzanych linków lub załączników otrzymanych za pośrednictwem poczty elektronicznej, które mogą pochodzić od nieznanych nadawców; 
                      </li>
                      <li>
                        używanie silnych, unikalnych haseł dla różnych serwisów internetowych, Usług oraz regularne ich aktualizowanie;
                      </li>
                      <li>
                        instalowanie i aktualizowanie oprogramowania antywirusowego oraz firewall’a na urządzeniach, z których korzysta się z Internetu, Stron internetowych oraz Usług; 
                      </li>
                      <li>
                        weryfikowanie pochodzenia autentyczności Stron internetowych oraz Usług przed udostępnieniem jakichkolwiek danych osobowych.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Usługodawca zobowiązuje się do informowania Użytkowników o znanych zagrożeniach związanych z korzystaniem z usług świadczonych drogą elektroniczną poprzez ogłoszenia lub aktualizacje w treści Regulaminu.
                  </li>
                </ol>

                <p class="mt-2">
                  Whistleblower - kanał komunikacji dla Sygnalistów.
                </p>
                <p class="mt-2">
                  Whistleblower to dwukierunkowy kanał komunikacji, spełniający wymogi dyrektywy. Sygnalista otrzymuje przywilej bycia anonimowym, jednocześnie nie traci możliwości prowadzenia dialogu z firmą.
                </p>

              </div>
            </div>
            </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
